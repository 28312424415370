import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import Dates from "../components/Dates";
import HtmlParser from "../components/HtmlParser";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import OtherEvents from "../components/Blocks/OtherEvents";

const Event = ({pageContext, data}) => {
  const {
    breadcrumb: {crumbs},
  } = pageContext

  let {alt, width, height} = data.node.image
  let [maxWidth, maxHeight] = [576, 576]
  let calcHeight = height * maxWidth / width

  if (calcHeight > maxHeight) {
    maxWidth = maxHeight * maxWidth / calcHeight
  }

  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title} />
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container">
          <Title
              title={data.node.title}
              subtitle={
                  <Dates start={data.node.dates.value} end={data.node.dates.end_value} className="has-text-centered"/>
              }
              className="has-text-centered"
          />

          {data.node.relationships.image && (
              <div className="block figure has-text-centered">
                <figure className="image" style={{margin: '0 auto',maxWidth: `${maxWidth}px`}}>
                    <GatsbyImage
                        image={data.node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                        alt={alt ? alt : data.node.title} />
                </figure>
            </div>
          )}

          {data.node.emplacement && (
            <address className="block address">
                <span className="icon">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
              <strong>Adresse :</strong> {data.node.emplacement}
            </address>
          )}

          {data.node.body && (
            <div className="block content" data-typesense-field="body">
                <HtmlParser html={data.node.body.value}/>
            </div>
          )}
        </div>
      </section>
      <OtherEvents current={pageContext.internalId} limit={3}/>
    </Layout>
  )
}

export default Event

export const query = graphql`
    query($internalId: Int!) {
        node: nodeEvenement(
            status: {eq: true},
            drupal_internal__nid: {eq: $internalId}
        ) {
            title
            body {
                value
            }
            image: field_image {
              alt
              width
              height
            }
            dates: field_date {
                value(formatString: "DD/MM/Y")
                end_value(formatString: "DD/MM/Y")
            }
            emplacement: field_emplacement
            internal {
                type
            }
            relationships {
                image: field_image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 576, layout: CONSTRAINED)
                        }
                    }
                }
            }
        }
    }
`
