import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import EventListItem from "../ListItems/EventListItem";

const OtherEvents = ({current, limit}) => {
    const data = useStaticQuery(graphql`{
        otherEvents: allNodeEvenement(
            sort: {
                fields: [field_date___value, field_date___end_value], 
                order: ASC
            },
            filter: {
                is_forthcoming: {eq: true}, 
                status: {eq: true},
            }
            limit: 4
        ) {
            nodes {
                nid: drupal_internal__nid
                title
                path {
                    alias
                }
                dates: field_date {
                    value(formatString: "DD/MM/Y")
                    end_value(formatString: "DD/MM/Y")
                }
                emplacement: field_emplacement
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 480
                                    height: 270
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                }
            }
        }
    }`)

  let otherEvents = data.otherEvents.nodes.filter(({nid}) => {
    return nid !== current;
  })

  otherEvents = otherEvents.slice(0, limit);
  
  return otherEvents.length > 0 ? (
    <section className="section page-content-suggestion has-background-image--contain-center" style={{backgroundImage: 'url(/dots.png)'}}>
        <div className="container">
            <h2 className="title is-2">Autres évènements à venir</h2>
            <div className="columns">
                {otherEvents.map((node, index) => (
                    <div key={index} className="column is-6 is-4-desktop">
                        <EventListItem content={node} />
                    </div>
                ))}
            </div>
        </div>
    </section>
  ) : (
    <></>
  )
}

export default OtherEvents